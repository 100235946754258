
import { defineComponent, ref, provide } from 'vue'
import ReportLineChart from '@/midone/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/midone/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/midone/components/report-pie-chart/Main.vue'
import ReportMap from '@/midone/components/report-map/Main.vue'
import ReportDonutChart1 from '@/midone/components/report-donut-chart-1/Main.vue'
import SimpleLineChart1 from '@/midone/components/simple-line-chart-1/Main.vue'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1
  },
  setup () {
    const salesReportFilter = ref('')
    const importantNotesRef = ref<HTMLElement>()

    provide('bind[importantNotesRef]', (el: HTMLElement) => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value
      el.tns.goTo('next')
    }

    return {
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes
    }
  }
})
